// import Swiper , { Navigation } from 'swiper';
// Swiper.use([Navigation]);
// 單一一個
// new Swiper('.swiper-container', {

var swiperContainer = new Swiper(".swiper-index", {

    loop: true,

    slidesPerView: 1,
    spaceBetween: 0,
    paginationClickable: true,

    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    // pagination: {
    //     el: '.swiper-pagination',
    //     clickable: true,
    // },
    breakpoints: {
        1024: {
            slidesPerView: 4,
            spaceBetween: 30
        },
        768: {
            slidesPerView: 2,
            spaceBetween: 30
        },
        480: {
            slidesPerView: 1,
            spaceBetween: 10
        }
    }
});
